import * as Sentry from '@sentry/react';

const SENTRY_DSN =
  'https://4d11b54b44694a979c5b97ca31e4d726@o4504424965668864.ingest.sentry.io/4504424966848512';

if (window.RAILS_ENV === 'production') {
  Sentry.init({
    dsn: SENTRY_DSN,
    integrations: [Sentry.browserTracingIntegration()],
    release: window.COMMIT_SHA,

    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 0.2,
  });
}

const componentRequireContext = require.context('components', true);
const ReactRailsUJS = require('react_ujs');
ReactRailsUJS.useContext(componentRequireContext);
